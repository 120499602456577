import React from 'react';

import style from './GameCard.module.scss';
import Button from '../button/Button.component';

export interface GameCardProps {
  title: string;
  description: string;
  imageUrl: string;
  onPlay: () => void;
  onInformation: () => void;
}

const GameCard: React.FC<GameCardProps> = ({
  title,
  description,
  imageUrl,
  onPlay,
  onInformation,
}) => (
  <div className={style.gameCard}>
    <div
      className={style.gameCard__image}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
    <h1 className={style.gameCard__gameTitle}>{title}</h1>
    <h2 className={style.gameCard__gameDescription}>{description}</h2>
    <div className={style.gameCard__buttonsContainer}>
      <Button
        size="small"
        value="Informations"
        reversed
        onClick={onInformation}
      />
    </div>
    <div className={style.gameCard__buttonsContainer}>
      <Button size="small" value="Je joue" onClick={onPlay} />
    </div>
  </div>
);

export default GameCard;
