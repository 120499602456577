import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './HowTo.module.scss';

const HowTo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <p className={styles.label}>
        <span className={styles.icon} />
        <span>{t('howTo.label')}</span>
      </p>
      <ol>
        <li>
          <span>1</span>
          <span>{t('howTo.step1')}</span>
        </li>
        <li>
          <span>2</span>
          <span>{t('howTo.step2')}</span>
        </li>
        <li>
          <span>3</span>
          <span>{t('howTo.step3')}</span>
        </li>
      </ol>
    </div>
  );
};

export default HowTo;
