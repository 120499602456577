import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { AppContext } from '../contexts/AppContext';

const useRedirection = (): void => {
  const { token, session } = queryString.parse(document.location.search);
  const { setSession, isProfileCompleted, setNextRoute } =
    useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!isProfileCompleted) {
      setNextRoute(
        `${document.location.pathname}${document.location.search}${document.location.hash}`,
      );
      history.push('/profile');
      return;
    }
    if (typeof session === 'string') {
      setSession(session);
      history.push('/training');
      return;
    }
    if (document.location.hash) {
      history.push(`/${document.location.hash}`);
      return;
    }
    if (typeof token === 'string') {
      history.push('/');
    }
  }, [history, isProfileCompleted, session, setNextRoute, setSession, token]);
};

export default useRedirection;
