import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { AppProvider } from './contexts/AppContext';
import useAuthentication from './hooks/useAuthentication';
import useProfileLoader from './hooks/useProfileLoader';
import HomePage from './pages/home-page';
import Loading from './pages/loading';
import Game from './pages/game/Game.component';
import Profile from './pages/profile';
import Cgu from './pages/cgu';
import End from './pages/end';
import Error from './pages/error';
import Contact from './pages/contact';

import fr from './i18n/fr';
import './App.scss';

i18n.use(initReactI18next).init({
  resources: {
    fr: { translation: fr },
  },
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

const App: React.FC = () => {
  const [session, setSession] = useState<string>();
  const [nextRoute, setNextRoute] = useState<string>();
  const { isAuthenticated, token } = useAuthentication();
  const { isProfileCompleted, setIsProfileCompleted } = useProfileLoader(token);

  if (
    isAuthenticated === undefined ||
    (isAuthenticated && isProfileCompleted === undefined)
  ) {
    return <Loading />;
  }
  return (
    <AppProvider
      value={{
        token,
        session,
        setSession,
        isProfileCompleted,
        setIsProfileCompleted,
        nextRoute,
        setNextRoute,
      }}
    >
      <div className="App">
        <Toaster />
        <Router>
          <Switch>
            <Route path="/cgu" component={Cgu} />
            <Route path="/error" component={Error} />
            <Route path="/contact" component={Contact} />
            {isAuthenticated && (
              <>
                <Route path="/game/:slug?" component={Game} />
                <Route path="/training" component={Game} />
                <Route path="/profile" component={Profile} />
                <Route path="/end" component={End} />
                <Route path="/auth" component={HomePage} />
                <Route exact path="/" component={HomePage} />
              </>
            )}
            <Route path="/" render={() => <Redirect to="/error" />} />
          </Switch>
        </Router>
      </div>
    </AppProvider>
  );
};

export default App;
