import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header';
import Button from '../../components/button/Button.component';
import Footer from '../../components/footer';

import styles from './End.module.scss';

const End: React.FC = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  if (redirect) return <Redirect to="/" />;
  return (
    <>
      <Header />
      <main className={styles.container}>
        <div className={styles.texts}>
          <p>{t('end.bravo')}</p>
          <p>{t('end.seeYou')}</p>
          <Button onClick={() => setRedirect(true)} value={t('end.btn')} />
        </div>
        <div className={styles.img} />
      </main>
      <Footer />
    </>
  );
};

export default End;
