import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RangeSlider from '../range-slider';
import RadioImage from '../radio-image';
import Button from '../button/Button.component';

import MenIcon from '../../assets/images/men.png';
import WomenIcon from '../../assets/images/women.png';
import BrevetIcon from '../../assets/images/brevet.png';
import BacIcon from '../../assets/images/bac.png';
import SuperiorIcon from '../../assets/images/superior.png';

import { IFormValues, IEntryFormProps } from './EntryForm.types';

import styles from './EntryForm.module.scss';
import Message from '../message';

const EntryForm: React.FC<IEntryFormProps> = ({
  onSubmitValues,
  defaultValues,
}) => {
  const { t } = useTranslation();

  const yearToIsoDate = (year: number) => new Date(year, 1).toISOString();

  const [isCguChecked, setIsCguChecked] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isFormSending, setIsFormSending] = useState(false);
  const [formValues, setFormValues] = useState<IFormValues>(defaultValues);

  const isFormValuesEmpty = () =>
    Object.values(formValues).some((value) => value === null);

  useEffect(() => {
    setFormValues(defaultValues);
  }, [defaultValues]);

  const onSubmit = () => {
    if (isFormValuesEmpty()) {
      setDisplayErrorMessage(true);
      setMessage(t('entryForm.errorForm'));
    } else if (!isCguChecked) {
      setDisplayErrorMessage(true);
      setMessage(t('entryForm.errorCgu'));
    } else {
      setIsFormSending(true);
      const formValuesToSend = { ...formValues };
      formValuesToSend.birthdate = yearToIsoDate(
        formValuesToSend.birthdate as number,
      );
      onSubmitValues(formValuesToSend);
    }
  };

  return (
    <form className={styles.container} onSubmit={(e) => e.preventDefault()}>
      <div className={styles.line}>
        <div className={`${styles.block} ${styles.block_1}`}>
          <p>{t('entryForm.sexe.label')}</p>
          <div className={styles.radio}>
            <RadioImage
              id="man"
              name="sexe"
              value="H"
              onChange={(value) =>
                setFormValues({ ...formValues, sexe: value })
              }
              label={t('entryForm.sexe.man')}
              image={MenIcon}
              selected={formValues.sexe === 'H'}
            />
            <RadioImage
              id="woman"
              name="sexe"
              value="F"
              onChange={(value) =>
                setFormValues({ ...formValues, sexe: value })
              }
              label={t('entryForm.sexe.woman')}
              image={WomenIcon}
              selected={formValues.sexe === 'F'}
            />
          </div>
        </div>

        <div className={`${styles.block} ${styles.block_2}`}>
          <p>{t('entryForm.education.label')}</p>
          <div className={styles.radio}>
            <RadioImage
              id="education_1"
              name="education"
              value={1}
              onChange={(value) =>
                setFormValues({ ...formValues, education: parseInt(value, 10) })
              }
              label={t('entryForm.education.level1')}
              image={BrevetIcon}
              selected={formValues.education === 1}
            />
            <RadioImage
              id="education_2"
              name="education"
              value={2}
              onChange={(value) =>
                setFormValues({ ...formValues, education: parseInt(value, 10) })
              }
              label={t('entryForm.education.level2')}
              image={BacIcon}
              selected={formValues.education === 2}
            />
            <RadioImage
              id="education_3"
              name="education"
              value={3}
              onChange={(value) =>
                setFormValues({ ...formValues, education: parseInt(value, 10) })
              }
              label={t('entryForm.education.level3')}
              image={SuperiorIcon}
              selected={formValues.education === 3}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.block} ${styles.block_3}`}>
        <p>{t('entryForm.birthdate.label')}</p>
        <RangeSlider
          min={1900}
          max={2010}
          step={1}
          onChange={(value) =>
            setFormValues({ ...formValues, birthdate: value })
          }
          value={formValues.birthdate as number}
        />
      </div>
      {displayErrorMessage && <Message text={message} />}
      <div className={styles.cgu}>
        <label htmlFor="CGU">
          <input
            id="CGU"
            type="checkbox"
            checked={isCguChecked}
            onChange={() => setIsCguChecked(!isCguChecked)}
          />
          <p>
            {t('entryForm.cgu.part1')}
            <Link to="/cgu" target="_blank">
              {t('entryForm.cgu.part2')}
            </Link>
            {t('entryForm.cgu.part3')}
          </p>
        </label>
      </div>

      <div className={styles.btn}>
        <Button
          isLoading={isFormSending}
          value={t('validate')}
          onClick={onSubmit}
        />
      </div>
    </form>
  );
};

export default EntryForm;
