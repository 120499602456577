import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

import useAxios from '../../utils/useAxios';
import { AppContext } from '../../contexts/AppContext';
import useLocationHash from '../../hooks/useLocationHash';
import useRedirection from '../../hooks/useRedirection';
import Header from '../../components/header';
import BlockTitle from '../../components/block-title';
import HowTo from '../../components/how-to';
import Headline from '../../components/headline/Headline';
import SessionForm from '../../components/session-form';
import { Carousel } from '../../components/carousel';
import Modal from '../../components/modal';
import Footer from '../../components/footer';
import GameInfo from '../../components/game-info/GameInfo.component';
import gameList, { GameInfoType } from '../../gameList';
import { ITrainingFormValues } from '../../components/session-form/SessionForm.types';
import styles from './HomePage.module.scss';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useContext(AppContext);
  const { setTrainingParams } = useAxios();
  const [information, setInformation] = useState<GameInfoType>();
  const [isFormSending, setIsFormSending] = useState(false);

  useRedirection();
  useLocationHash();

  const successToast = () => {
    toast(t('homepage.sessionPlanner.successToast'), {
      duration: 4000,
      position: 'top-center',
      style: {
        textAlign: 'left',
        backgroundColor: '#60a03a',
        color: 'white',
      },
    });
  };

  const handleSessionForm = async (formValues: ITrainingFormValues) => {
    setIsFormSending(true);
    if (!token) {
      history.push('/auth');
      return;
    }
    const success = await setTrainingParams(token, formValues);
    setIsFormSending(false);
    if (success) {
      successToast();
    }
  };

  const onPlay = (slug: string) => {
    history.push(`/game/${slug}`);
  };

  const hideInfo = () => {
    setInformation(undefined);
  };

  const displayInfo = (gameId: number) => {
    setInformation(gameList.find((game) => game.gameId === gameId));
  };

  if (!token) {
    return <Redirect to="/auth" />;
  }
  return (
    <>
      <Modal visible={!!information}>
        {information && (
          <GameInfo
            gameInfo={information}
            onClick={() => {
              onPlay(information.slug);
            }}
            onClose={hideInfo}
          />
        )}
      </Modal>
      <Header />
      <main>
        <div className={styles.white}>
          <Headline>
            <p>{t('homepage.mainTitle')}</p>
          </Headline>
          <div className={styles.container}>
            <BlockTitle
              title={t('homepage.games.title')}
              subtitle={t('homepage.games.subtitle')}
            />
            <Carousel
              slides={gameList.map((game) => ({
                ...game,
                onPlay: () => onPlay(game.slug),
                onInformation: () => displayInfo(game.gameId),
              }))}
            />
          </div>
        </div>
        <div className={styles.green} id="training-program">
          <span className={styles['circle-part']} />
          <div className={styles.container}>
            <BlockTitle
              title={t('homepage.sessionPlanner.title')}
              subtitle={t('homepage.sessionPlanner.subtitle')}
            />
            <HowTo />
            <SessionForm
              isFormSending={isFormSending}
              onSubmit={handleSessionForm}
            />
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default HomePage;
