import React from 'react';

interface IAppContext {
  token: string;
  session?: string;
  setSession: React.Dispatch<React.SetStateAction<string | undefined>>;
  isProfileCompleted?: boolean;
  setIsProfileCompleted: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  nextRoute?: string;
  setNextRoute: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);

const AppProvider = AppContext.Provider;

export { AppContext, AppProvider };
