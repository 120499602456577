import React from 'react';
import clx from 'classnames';

import style from './Modal.module.scss';

export interface ModalProps {
  visible?: boolean;
}

const Modal: React.FC<ModalProps> = ({ visible = false, children }) => (
  <div
    className={clx(style['modal-root'], {
      [style['modal-root--hidden']]: !visible,
    })}
  >
    <div className={style['modal-root__container']}>{children}</div>
  </div>
);

export default Modal;
