import React from 'react';

import styles from './BlockTitle.module.scss';

export interface IBlockTitleProps {
  title: string;
  subtitle: string;
}

const BlockTitle: React.FC<IBlockTitleProps> = ({ title, subtitle }) => (
  <div className={styles.container}>
    <h3>{title}</h3>
    <h5>{subtitle}</h5>
  </div>
);

export default BlockTitle;
