import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header';
import Button from '../../components/button/Button.component';
import Headline from '../../components/headline/Headline';
import Footer from '../../components/footer';

import styles from './Error.module.scss';

const Error: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Header />
      <main>
        <Headline>
          <p>
            {t('error.tryAgain')}
            <br />
            {t('error.support')}
          </p>
        </Headline>
        <div className={styles.container}>
          <Button onClick={() => history.push('/')} value={t('end.btn')} />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Error;
