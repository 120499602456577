import React from 'react';
import { GameInfoType } from '../../gameList';

import style from './GameInfo.module.scss';
import Button from '../button/Button.component';

interface GameInfoProps {
  gameInfo: GameInfoType;
  onClick: () => void;
  onClose: () => void;
}

const GameInfo: React.FC<GameInfoProps> = ({ gameInfo, onClick, onClose }) => (
  <div className={style.gameInfo}>
    <div role="button" className={style.gameInfo__close} onClick={onClose} />
    <div className={style.gameInfo__imageContainer}>
      <img
        alt={gameInfo.title}
        src={gameInfo.imageUrl}
        className={style.gameInfo__image}
      />
    </div>
    <div className={style.gameInfo__infoContainer}>
      <h1 className={style.gameInfo__title}>{gameInfo.title}</h1>
      <h1 className={style.gameInfo__category}>{gameInfo.category}</h1>
      <div className={style.gameInfo__description}>{gameInfo.information}</div>
      <div className={style.gameInfo__button}>
        <Button onClick={onClick}>Je joue</Button>
      </div>
    </div>
  </div>
);

export default GameInfo;
