let domain;
switch (window.location.origin || '') {
  case 'https://flex-staging.happyneuron.fr':
  case 'https://flex.happyneuron.fr':
    domain = window.location.origin;
    break;
  default:
    domain = 'https://flex-staging.happyneuron.fr';
}

export default {
  jags: {
    domain,
  },
  games: {
    domain: 'https://games.happyneuron.fr',
    page: 'aspx/launch.aspx',
    as: 'as01',
  },
};
