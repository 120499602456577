import React, { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

interface IUseProfileLoader {
  isProfileCompleted?: boolean;
  setIsProfileCompleted: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

const useProfileLoader = (token = ''): IUseProfileLoader => {
  const [loading, setLoading] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>();
  const { getProfile } = useAxios();

  useEffect(() => {
    if (!token || loading || isProfileCompleted !== undefined) return;
    setLoading(true);
    const retrieveProfile = async () => {
      setIsProfileCompleted(!!(await getProfile(token)));
    };
    retrieveProfile()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setIsProfileCompleted(false);
      });
  }, [getProfile, isProfileCompleted, loading, token]);

  return { isProfileCompleted, setIsProfileCompleted };
};

export default useProfileLoader;
