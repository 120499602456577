import React from 'react';

import Header from '../header';
import Footer from '../footer';

import styles from './Layout.module.scss';

const Layout: React.FC = ({ children }) => (
  <>
    <Header />
    <main className={styles.container}>{children}</main>
    <Footer />
  </>
);

export default Layout;
