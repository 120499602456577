export default {
  appName: 'flex',
  validate: 'Je valide mes informations',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
  letsGo: 'Je valide',
  entryForm: {
    sexe: {
      label: 'Sexe :',
      man: 'Homme',
      woman: 'Femme',
    },
    education: {
      label: "Niveau d'études\u00a0:",
      level1: "Certificat d'études",
      level2: 'Baccalauréat',
      level3: 'Études supérieures',
    },
    birthdate: {
      label: 'Année de naissance\u00a0:',
      empty: 'Sélectionnez une date',
    },
    cgu: {
      part1: 'Je reconnais avoir lu et compris les ',
      part2: 'Conditions générales d’utilisation',
      part3: ' et je les accepte.',
    },
    errorForm:
      'Merci de renseigner tous les éléments du formulaire pour continuer.',
    errorCgu:
      "Merci d'accepter les conditions générales d'utilisation pour continuer.",
  },
  homepage: {
    mainTitle:
      'Bienvenue sur le portail de stimulation cérébrale Flex, proposé par Happyneuron !',
    games: {
      title: 'Jeu libre',
      subtitle:
        "Profitez de 5 jeux en accès libre, pour stimuler vos neurones tout en vous amusant, et pour découvrir une partie de la collection de 50 jeux qui vous attend dans le programme d'entraînement !",
    },
    sessionPlanner: {
      title: "Programme d'entraînement",
      subtitle:
        'Paramètrez vos séances de stimulation cérébrale pour suivre l’entraînement qui vous sera le plus adapté ! Chaque séance vous propose des exercices choisis selon votre profil cognitif, parmi un catalogue de 50 exercices !',
      successToast: 'Vos modifications ont bien été prises en compte.',
    },
  },
  profile: {
    title: 'Avant de jouer, merci de compléter ces informations\u00a0:',
    subtitle:
      'Ces informations seront indispensables pour le calcul de vos scores et le suivi de vos résultats.',
  },
  howTo: {
    label: 'Comment ça marche ?',
    step1:
      'Vous choisissez la périodicité (tous les jours, 3 fois par semaine, etc.) et la durée (10 à 60 minutes) des séances d’entraînement.',
    step2:
      'Vous recevez par email un lien d’accès à chaque séance, à la fréquence que vous avez définie.',
    step3:
      'À la fin de chaque exercice, vous visualisez votre performance. Vous recevez également, à chaque fin de mois, un point sur vos résultats et un aperçu de votre évolution.',
  },
  loadingScreen: {
    noInternet: 'Aucune connexion internet détectée',
  },
  sessionFrequency: {
    label: "Fréquence de vos séances d'entraînement\u00a0:",
    content:
      'Pour assurer un entraînement régulier, vous pouvez choisir les jours où vous souhaitez recevoir une séance supervisée par email\u00a0:',
  },
  footer: {
    copyright: '© 2021 Stimulation cérébrale Flex, proposée par Happyneuron',
    rgpd: 'Politique de confidentialité des données',
    contact: 'Contact',
    mentions: 'Mentions légales et RGPD',
  },
  end: {
    btn: 'Retour au portail Flex',
    bravo: 'Votre séance du jour est terminée.',
    seeYou: 'Bravo et à bientôt !',
  },
  error: {
    tryAgain: "Une erreur s'est produite, merci de réessayer plus tard.",
    support: "Si le problème persiste, n'hésitez pas à contacter le support.",
  },
  sessionDuration: "Durée de chaque séance d'entraînement\u00a0:",
  screenOrientation: 'Pour jouer à ce jeu, retournez votre appareil.',
  contact: {
    title: 'Contacter Flex',
    subtitle1: "Besoin d'aide, une question\u00a0?",
    subtitle2: 'Nos conseillers sont là pour vous.',
    content1: 'Du lundi au vendredi de 8h30 à 17h30.',
    content2: 'Appelez le 09 69 39 33 00 (prix d’un appel local)',
  },
};
