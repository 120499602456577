import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreIcon from '../../assets/images/more.png';
import LessIcon from '../../assets/images/less.png';

import { IRangeSliderProps } from './RangeSlider.types';

import styles from './RangeSlider.module.scss';

const RangeSlider: React.FC<IRangeSliderProps> = ({
  min,
  max,
  step = 1,
  onChange,
  value,
}) => {
  const [tooltipXPosition, setTooltipXPosition] = useState(50);
  const { t } = useTranslation();

  useEffect(() => {
    if (!value) return;
    const percent = ((value - min) * 100) / (max - min);

    setTooltipXPosition(percent);
  }, [value, max, min]);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = parseInt(event.target.value, 10);
    onChange(nextValue);
  };

  const handleAddOneYear = () => {
    if (value === max) return;
    let nextValue;
    if (!value) {
      nextValue = min + 1 + (max - min) / 2;
    } else {
      nextValue = value + 1;
    }
    onChange(nextValue);
  };
  const handleRemoveOneYear = () => {
    if (value === min) return;
    let nextValue;
    if (!value) {
      nextValue = min - 1 + (max - min) / 2;
    } else {
      nextValue = value - 1;
    }
    onChange(nextValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <input
          min={min}
          max={max}
          type="number"
          value={value !== null ? value : ''}
          onChange={handleChangeValue}
        />
      </div>

      <div className={styles.slider}>
        <div className={styles.range}>
          <div className={styles.sliderValue}>
            <span
              style={{
                left: `calc(${tooltipXPosition}% + ${
                  5 - tooltipXPosition * 0.1
                }px)`,
              }}
            >
              {value !== null ? value : t('entryForm.birthdate.empty')}
            </span>
          </div>
          <div className={styles.field}>
            <input
              type="range"
              min={min}
              max={max}
              value={value !== null ? value : min + (max - min) / 2}
              step={step}
              onChange={handleChangeValue}
            />
            <span
              className={styles.fill}
              style={{
                width: `calc(${tooltipXPosition}% + ${
                  5 - tooltipXPosition * 0.1
                }px)`,
              }}
            />
            <div className={`${styles.value} ${styles.left}`}>{min}</div>
            <div className={`${styles.value} ${styles.right}`}>{max}</div>
            <div className={styles.icons}>
              <span
                role="button"
                onClick={handleRemoveOneYear}
                className={`${styles.icon} ${styles.left}`}
                style={{ backgroundImage: `url(${LessIcon}` }}
              />
              <span
                role="button"
                onClick={handleAddOneYear}
                className={`${styles.icon} ${styles.right}`}
                style={{ backgroundImage: `url(${MoreIcon}` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
