import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import queryString from 'query-string';
import dayjs from 'dayjs';

import useAxios from '../utils/useAxios';

interface IUseAuthentication {
  isAuthenticated?: boolean;
  token: string;
}

const cacheDuration = 1000 * 60 * 60;

const useAuthentication = (): IUseAuthentication => {
  const { isTokenValid } = useAxios();
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [token, setToken] = useState('');
  const { token: urlToken } = queryString.parse(document.location.search);
  const [lsToken, setLsToken] = useLocalStorage('token', '');
  const [lsExpiration, setLsExpiration] = useLocalStorage('expiration', '');

  const handleTokenExpiration = useCallback(() => {
    if (lsExpiration) {
      const cache = dayjs(lsExpiration);
      const now = dayjs();
      if (now.diff(cache) > cacheDuration) {
        setLsToken('');
      }
    }
    setLsExpiration(dayjs().format());
  }, [lsExpiration, setLsExpiration, setLsToken]);

  const authentication = useCallback(async (): Promise<boolean> => {
    let checkedToken;
    if (typeof urlToken === 'string') {
      checkedToken = urlToken;
    } else if (lsToken) {
      checkedToken = lsToken;
    }
    if (!checkedToken) return false;
    if (await isTokenValid(checkedToken)) {
      setToken(checkedToken);
      setLsToken(checkedToken);
      return true;
    }
    setToken('');
    setLsToken('');
    return false;
  }, [isTokenValid, lsToken, setLsToken, urlToken]);

  useEffect(() => {
    if (loading || isAuthenticated !== undefined) return;
    setLoading(true);
    handleTokenExpiration();
    authentication()
      .then((auth) => {
        setIsAuthenticated(auth);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsAuthenticated(false);
        setLoading(false);
      });
  }, [authentication, handleTokenExpiration, isAuthenticated, loading]);

  return { isAuthenticated, token };
};

export default useAuthentication;
