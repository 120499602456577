import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CircleCounter.module.scss';

export interface ICircleCounterProps {
  defaultValue?: number;
  max: number;
  min: number;
  step: number;
  onUpdate: (duration: number) => void;
}

const CircleCounter: React.FC<ICircleCounterProps> = ({
  defaultValue = 10,
  max,
  min,
  step,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const handlePercentValue = () => (currentValue / max) * 100;

  const handleAddDuration = () => {
    if (currentValue >= max) return;
    const nextValue = currentValue + step;
    setCurrentValue(nextValue);
    onUpdate(nextValue);
  };

  const handleRemoveDuration = () => {
    if (currentValue <= min) return;
    const nextValue = currentValue - step;
    setCurrentValue(nextValue);
    onUpdate(nextValue);
  };

  return (
    <div className={styles.container}>
      <p className={styles.label}>
        <span className={styles.icon} />
        <span>{t('sessionDuration')}</span>
      </p>
      <div className={styles.counter}>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          className={`${styles['circular-chart']} ${styles.blue}`}
        >
          {currentValue} min
          <path
            className={styles['circle-bg']}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={styles.circle}
            strokeDasharray={`${handlePercentValue()} 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className={styles.percentage}>
            <tspan x="18" y="16">
              {currentValue}
            </tspan>
            <tspan x="18" y="24">
              min
            </tspan>
          </text>
        </svg>
        <div className={styles.buttons}>
          <button
            type="button"
            className={`${currentValue <= min ? styles.disabled : ''}  ${
              styles.less
            }`}
            onClick={handleRemoveDuration}
          >
            -
          </button>
          <button
            type="button"
            className={`${currentValue >= max ? styles.disabled : ''}  ${
              styles.more
            }`}
            onClick={handleAddDuration}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default CircleCounter;
