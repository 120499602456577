import { useMemo } from 'react';
import axios from 'axios';

import { IFormValues } from '../components/entry-form/EntryForm.types';
import { ITrainingFormValues } from '../components/session-form/SessionForm.types';

import conf from '../env.conf';

interface IUseAxios {
  isTokenValid: (token: string) => Promise<boolean>;
  getTrainingParams: (
    token: string,
  ) => Promise<{ duration: number; days: (0 | 1)[] }>;
  getProfile: (token: string) => Promise<
    | {
        gsId?: number;
        gender?: number;
        education?: number;
        birthday?: Date;
      }
    | undefined
  >;
  setProfile: (token: string, data: IFormValues) => Promise<void>;
  setTrainingParams: (
    token: string,
    data: ITrainingFormValues,
  ) => Promise<boolean>;
}

const useAxios = (): IUseAxios => {
  const api = useMemo(
    () =>
      axios.create({
        baseURL: conf.jags.domain,
        responseType: 'json',
      }),
    [],
  );

  const isTokenValid = async (token: string): Promise<boolean> => {
    try {
      const result = await api.get(`/ws/users/auth?token=${token}`);
      return result.status === 200;
    } catch (err) {
      return false;
    }
  };

  const getTrainingParams = async (
    token: string,
  ): Promise<{ duration: number; days: (0 | 1)[] }> => {
    try {
      const result = await api.get(`/ws/training?token=${token}`);
      if (result.status !== 200) {
        throw new Error('Cannot retrieve training information');
      }
      return {
        duration: result.data.duration,
        days: [
          result.data.day_1 ? 1 : 0,
          result.data.day_2 ? 1 : 0,
          result.data.day_3 ? 1 : 0,
          result.data.day_4 ? 1 : 0,
          result.data.day_5 ? 1 : 0,
          result.data.day_6 ? 1 : 0,
          result.data.day_7 ? 1 : 0,
        ],
      };
    } catch (err) {
      return { duration: 10, days: [] };
    }
  };

  const setTrainingParams = async (
    token: string,
    data: ITrainingFormValues,
  ): Promise<boolean> => {
    try {
      const response = await api.post(`/ws/training?token=${token}`, data);
      return response.status === 200;
    } catch (err) {
      console.error('Cannot update training');
      return false;
    }
  };
  const getProfile = async (
    token: string,
  ): Promise<
    | {
        gsId?: number;
        gender?: number;
        education?: number;
        birthday?: Date;
      }
    | undefined
  > => {
    try {
      const result = await api.get(`/ws/profile?token=${token}`);
      if (result.data.gsId === null) {
        return undefined;
      }
      return result.data;
    } catch (err) {
      return undefined;
    }
  };

  const setProfile = async (
    token: string,
    data: IFormValues,
  ): Promise<void> => {
    try {
      await api.post(`/ws/profile?token=${token}`, data);
    } catch (err) {
      console.error('Cannot update profile');
    }
  };

  return {
    isTokenValid,
    getTrainingParams,
    getProfile,
    setProfile,
    setTrainingParams,
  };
};

export default useAxios;
