import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToLandscapeAnimation from '../../assets/animations/tolandscape.svg';

import styles from './ScreenOrientation.module.scss';

const ScreenOrientation: React.FC = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(false);
  const { t } = useTranslation();

  const checkIsLandscape = (): boolean => {
    let rotationType: OrientationType = window.screen.orientation?.type || '';
    if (!rotationType) {
      const findOrientation = window.matchMedia('(orientation: portrait)');
      if (findOrientation.matches) {
        rotationType = 'portrait-primary';
      } else {
        rotationType = 'landscape-primary';
      }
    }
    return rotationType.includes('landscape');
  };

  useEffect(() => {
    setIsLandscape(checkIsLandscape());

    window.addEventListener('orientationchange', () => {
      setIsLandscape(checkIsLandscape());
    });

    return () => {
      window.removeEventListener('orientationchange', () =>
        setIsLandscape(checkIsLandscape()),
      );
    };
  }, []);

  return isLandscape ? (
    <>{children}</>
  ) : (
    <div className={styles.container}>
      <img src={ToLandscapeAnimation} alt="please turn your phone" />
      <p>{t('screenOrientation')}</p>
    </div>
  );
};

export default ScreenOrientation;
