import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SessionFrequency from '../session-frequency';
import CircleCounter from '../circle-counter';
import Button from '../button/Button.component';

import { ISessionFormProps, ITrainingFormValues } from './SessionForm.types';

import styles from './SessionForm.module.scss';
import useAxios from '../../utils/useAxios';
import { AppContext } from '../../contexts/AppContext';

const SessionForm: React.FC<ISessionFormProps> = ({
  onSubmit,
  isFormSending,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<ITrainingFormValues>({
    duration: 10,
    days: [],
  });
  const [loadedData, setLoadedData] = useState(false);
  const { token } = useContext(AppContext);
  const { getTrainingParams } = useAxios();

  useEffect(() => {
    if (loadedData) return;
    const loadData = async () => {
      if (!token) return;
      const { duration, days } = await getTrainingParams(token);
      setFormValues({ duration, days });
      setLoadedData(true);
    };
    loadData();
  }, [getTrainingParams, loadedData, token]);

  return (
    <div className={styles.container}>
      <div className={styles.blocks}>
        <SessionFrequency
          defaultValue={formValues.days}
          onUpdate={(selectedDays) =>
            setFormValues({ ...formValues, days: selectedDays })
          }
        />
        <CircleCounter
          defaultValue={formValues.duration}
          max={60}
          min={10}
          step={10}
          onUpdate={(selectedDuration) =>
            setFormValues({ ...formValues, duration: selectedDuration })
          }
        />
      </div>
      <Button
        isLoading={isFormSending}
        value={t('letsGo')}
        onClick={() => onSubmit(formValues)}
      />
    </div>
  );
};

export default SessionForm;
