import React from 'react';
import { useTranslation } from 'react-i18next';
import { Offline, Online } from 'react-detect-offline';

import Headline from '../../components/headline/Headline';
import styles from '../../components/header/Header.module.scss';

import LogoFlex from '../../assets/images/logo-flex.png';
import LogoHn from '../../assets/images/logo-hn.png';
import loadingImg from '../../assets/images/loading.gif';

const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${styles.header} ${styles.top}`}>
        <img src={LogoFlex} alt="logo flex" />
        <img src={LogoHn} alt="logo happyneuron" />
      </div>
      <main>
        <Headline>
          <Online>
            <p>
              <img src={loadingImg} alt="chargement en cours" />
            </p>
          </Online>
          <Offline>
            <p>{t('loadingScreen.noInternet')}</p>
          </Offline>
        </Headline>
      </main>
    </>
  );
};

export default Loading;
