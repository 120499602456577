import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { GameCard, GameCardProps } from '../game-card';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './Carousel.scss';

SwiperCore.use([Navigation, Pagination]);

export interface CarouselProps {
  slides: GameCardProps[];
}

const Carousel: React.FC<CarouselProps> = ({ slides }) => (
  <div className="carousel-container">
    <span
      className="icon icon--prev"
      style={{
        display: 'block',
      }}
    />
    <span
      className="icon icon--next"
      style={{
        display: 'block',
      }}
    />
    <Swiper
      spaceBetween={20}
      pagination={{
        clickable: true,
      }}
      updateOnWindowResize
      navigation={{
        nextEl: '.icon--next',
        prevEl: '.icon--prev',
      }}
      breakpoints={{
        1050: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          width: 960,
        },
        720: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          width: 640,
        },
        320: {
          slidesPerGroup: 1,
          slidesPerView: 1,
          width: 290,
          spaceBetween: 5,
        },
      }}
    >
      {slides.map((cardProps) => {
        const { title, description, imageUrl, onPlay, onInformation } =
          cardProps;
        return (
          <SwiperSlide key={cardProps.title}>
            <GameCard
              title={title}
              description={description}
              imageUrl={imageUrl}
              onPlay={onPlay}
              onInformation={onInformation}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  </div>
);

export default Carousel;
