import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISessionFrequencyProps, IDay } from './SessionFrequency.types';

import styles from './SessionFrequency.module.scss';

const SessionFrequency: React.FC<ISessionFrequencyProps> = ({
  onUpdate,
  defaultValue = [],
}) => {
  const { t } = useTranslation();

  const [days, setDays] = useState<IDay[]>([
    { name: 'monday', selected: 0 },
    { name: 'tuesday', selected: 0 },
    { name: 'wednesday', selected: 0 },
    { name: 'thursday', selected: 0 },
    { name: 'friday', selected: 0 },
    { name: 'saturday', selected: 0 },
    { name: 'sunday', selected: 0 },
  ]);

  useEffect(() => {
    setDays((prevDays) =>
      prevDays.map((d, index) => ({
        ...d,
        selected: defaultValue[index] || 0,
      })),
    );
  }, [defaultValue]);

  const handleSelectDay = (selectedDay: string) => {
    const newSelectedDays = days.map((day) => {
      if (day.name === selectedDay) day.selected = day.selected ? 0 : 1;
      return day;
    });

    setDays(newSelectedDays);
    onUpdate(newSelectedDays.map((day) => (day.selected ? 1 : 0)));
  };

  return (
    <div className={styles.container}>
      <p className={styles.label}>
        <span className={styles.icon} />
        <span>{t('sessionFrequency.label')}</span>
      </p>
      <p className={styles.text}>{t('sessionFrequency.content')}</p>
      <div className={styles.days}>
        {days.map((day) => (
          <div
            key={day.name}
            role="button"
            className={day.selected ? styles.selected : ''}
            onClick={() => handleSelectDay(day.name)}
          >
            <span className={styles.fullname}>{t(day.name)}</span>
            <span className={styles.abbrev}>{t(day.name)[0]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionFrequency;
