import React, { useContext, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { AppContext } from '../../contexts/AppContext';
import ScreenOrientation from '../../components/screen-orientation';

import style from './Game.module.scss';
import IconHome from '../../assets/images/home.svg';
import LogoFlex from '../../assets/images/logo-flex.png';
import LogoHn from '../../assets/images/logo-hn.png';

import conf from '../../env.conf';
import gameList from '../../gameList';
import useRedirection from '../../hooks/useRedirection';

const Game: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const { session } = useContext(AppContext);
  const { token } = useContext(AppContext);

  const gameId = gameList.find((game) => game.slug === slug)?.gameId;

  const gameUrl = useMemo(() => {
    if (gameId) {
      return `${conf.games.domain}/${conf.games.page}?as=${conf.games.as}&t=${token}&gamenum=${gameId}`;
    }
    if (session) {
      const endGamePage = encodeURIComponent(
        `fin.aspx?p=https://${window.location.hostname}/end`,
      );
      const firstGame = session.substr(0, session.indexOf(','));
      return `${conf.games.domain}/${conf.games.page}?as=${conf.games.as}&t=${token}&supsconfig=${session}&supsession=1&autoCoach=1&gamenum=${firstGame}&endUrl=${endGamePage}`;
    }
    return '';
  }, [gameId, session, token]);

  useRedirection();

  return (
    <ScreenOrientation>
      <div className={style.gamePage}>
        <div className={style.gamePage__header}>
          <Link to="/">
            <img
              className={style['gamePage__header__icon--mobile']}
              src={IconHome}
              alt="Icon Accueil"
            />
          </Link>
          <img
            className={style.gamePage__header__icon}
            src={LogoFlex}
            alt="logo flex"
          />
          <img
            className={style.gamePage__header__icon}
            src={LogoHn}
            alt="logo happyneuron"
          />
        </div>
        <div className={style.gamePage__game}>
          <iframe
            title={`hn-game-${gameId}`}
            className={style.gamePage__game__object}
            src={gameUrl}
          />
        </div>
      </div>
    </ScreenOrientation>
  );
};

export default Game;
