import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout';

import IllustrationContact from '../../assets/images/illustration-contact.svg';

import styles from './Contact.module.scss';

const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <h1>{t('contact.title')}</h1>
      <div className={styles.container}>
        <img src={IllustrationContact} alt="Illustration page de contact" />
        <div>
          <h2>
            <p>{t('contact.subtitle1')}</p>
            <p>{t('contact.subtitle2')}</p>
          </h2>
          <p>{t('contact.content1')}</p>
          <p>{t('contact.content2')}</p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
