import React from 'react';

import Layout from '../../components/layout';

const Cgu: React.FC = () => (
  <Layout>
    <h1>
      Conditions générales d&#39;utilisation <br />
      Du service Happyneuron pour Flex
    </h1>
    <p>En vigueur au 01/10/2021</p>
    <p>
      Les présentes conditions générales d&#39;utilisation (dites « CGU ») ont
      pour objet l&#39;encadrement juridique des modalités de mise à disposition
      du site et des services par HUMAN(S) MATTER SAS et de définir les
      conditions d’accès et d’utilisation des services par « l&#39;Utilisateur
      ».
    </p>
    <p>Les présentes CGU sont accessibles sur le site à la rubrique «CGU».</p>
    <p>
      Toute inscription ou utilisation du site implique l&#39;acceptation sans
      aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de
      l&#39;inscription sur le site via le Formulaire d’inscription, chaque
      utilisateur accepte expressément les présentes CGU en cochant la case
      précédant le texte suivant : « Je reconnais avoir lu et compris les
      Conditions générales d’utilisation et je les accepte. ».
    </p>
    <p>
      En cas de non-acceptation des CGU stipulées dans le présent contrat,
      l&#39;Utilisateur se doit de renoncer à l&#39;accès des services proposés
      par le site.
    </p>
    <p>
      flex.happyneuron.fr se réserve le droit de modifier unilatéralement et à
      tout moment le contenu des présentes CGU.
    </p>
    <h2>Article 1 : Les mentions légales</h2>
    <p>
      L&#39;édition du site flex.happyneuron.fr est assurée par la Société SAS
      HUMAN(S) MATTER au capital de 339 750 euros, immatriculée au RCS de PARIS
      sous le numéro 451 130 744, dont le siège social est situé au 114 Bd
      Malesherbes - 75017 Paris
    </p>
    <p>Numéro de téléphone : 01 42 61 16 01</p>
    <p>
      Formulaire de contact :{' '}
      <a href="https://www.sbt-human.com/prise-de-contact/">
        https://www.sbt-human.com/prise-de-contact/
      </a>
    </p>
    <p>Le Directeur de la publication est : Franck Tarpin-Bernard</p>
    <p>
      L&#39;hébergeur du site flex.happyneuron.f est la société OVH, dont le
      siège social est situé Route de la ferme Masson - 59820 GRAVELINES , avec
      le numéro de téléphone : 1007.
    </p>
    <h2>ARTICLE 2 : Accès au site</h2>
    <p>
      Le site flex.happyneuron.fr permet à l&#39;Utilisateur un accès
      personnalisé à un service d&#39;entraînement cognitif.
    </p>
    <p>
      Le site est accessible en tout lieu à tout Abonné au portail de
      stimulation cérébrale Flex ayant un accès à Internet. Tous les frais
      supportés par l&#39;Utilisateur pour accéder au service (matériel
      informatique, logiciels, connexion Internet, etc.) sont à sa charge.
    </p>
    <p>
      Un Utilisateur non membre n&#39;a pas accès aux services. Pour cela, il
      doit être client du service Flex. En acceptant de s’inscrire aux services
      réservés, l’Utilisateur membre s’engage à fournir des informations
      sincères et exactes concernant son état civil et ses coordonnées,
      notamment son adresse email.
    </p>
    <p>
      Pour accéder aux services, l’Utilisateur doit s’être identifié sur la
      plateforme Flex.
    </p>
    <p>
      Tout Utilisateur membre régulièrement inscrit pourra également solliciter
      sa désinscription en se rendant à la page dédiée sur son espace personnel.
      Celle-ci sera effective dans un délai raisonnable.
    </p>
    <p>
      Tout événement dû à un cas de force majeure ayant pour conséquence un
      dysfonctionnement du site ou serveur et sous réserve de toute interruption
      ou modification en cas de maintenance, n&#39;engage pas la responsabilité
      de flex.happyneuron.fr. Dans ces cas, l’Utilisateur accepte ainsi ne pas
      tenir rigueur à l’éditeur de toute interruption ou suspension de service,
      même sans préavis.
    </p>
    <p>
      L’utilisateur a la possibilité de contacter le service client Flex au
      numéro suivant : 09 69 39 33 00 du lundi au vendredi de 8h30 à 17h30 (prix
      d’un appel local).
    </p>
    <h2>ARTICLE 3 : Collecte des données</h2>
    <p>
      Pour utiliser le site flex.happyneuron.fr, des Données administratives
      (email et prénom) du client sont collectées et communiquées par Flex
      Conseil et Services, puis traitées par HUMAN(S) MATTER pour gérer l’accès
      au service et communiquer avec le client.
    </p>
    <p>Le site flex.happyneuron.fr collecte :</p>
    <ul>
      <li>
        Des données démographiques strictement nécessaires au fonctionnement du
        service (année de naissance, sexe, niveau d’étude) sont demandées à la
        première connexion. Elles permettent d’adapter l&#39;algorithme des
        séances personnalisées et de situer les résultats des utilisateurs par
        rapport à leur cohorte.
      </li>
    </ul>
    <ul>
      <li>
        Les données d’usage du client. Nous analysons ces données afin
        d’améliorer le service, en proposant des correctifs, évolutions et
        nouveautés qui répondent à ces usages.
      </li>
    </ul>
    <p>
      Les données d’usage sont conservées sans limitation de durée, à des fins
      de statistiques.
      <br />
      Les données d’identification (email, Prénom de l’utilisateur) sont
      automatiquement anonymisées au désabonnement du service Flex contenant
      l’accès au Portail de stimulation cérébrale.
    </p>
    <p>
      Aucune information personnelle de l’utilisateur du site
      flex.happyneuron.fr n’est publiée à l’insu de l’utilisateur, échangée,
      transférée, cédée ou vendue sur un support quelconque à des tiers.
    </p>
    <p>
      Les droits exigés par le RGPD sont garantis aux utilisateurs s’ils en font
      la demande : effacement, non-profilage, portabilité, rectification, droit
      à l’oubli, à la limitation ou l’opposition d’un traitement de données.
      <br />
      Pour faire respecter ces droits merci d’adresser un courrier à HAPPYNEURON
      - HUMAN(S) MATTER, Rue de Lanoux, 31330 Grenade sur Garonne, accompagné
      d’une copie du titre d’identité avec signature du titulaire de la pièce,
      en précisant l’adresse à laquelle la réponse doit être envoyée.
      <br />
      Les délais de traitement de la demande peuvent varier entre un et trois
      mois suivant la complexité de cette dernière.{' '}
    </p>
    <p>
      Pour garantir la sécurité des données l’ensemble des échanges de données
      entre le client et les serveurs sont cryptés avec le protocole SSL
      (https).
    </p>
    <p>
      HUMAN(S) MATTER fait appel à des Hébergeurs pour ses serveurs. Ces
      hébergeurs sont sélectionnés afin de garantir un niveau de sécurité et des
      obligations de confidentialité appropriés aux types de données hébergées.
      HUMAN(S) MATTER est tenu de conclure un contrat avec tout Sous-traitant
      qui lui impose de protéger les Données à caractère personnel en vertu des
      réglementations applicables et notamment le RGPD.
    </p>
    <p>
      Les serveurs de HUMAN(S) MATTER sont situés en France. Les données ne sont
      donc pas transférées en dehors de l’Union européenne.
    </p>
    <p>
      L’approche générale du groupe SBT HUMAN(S) MATTER est explicité sur cette
      page :{' '}
      <a href="https://www.sbt-human.com/rgpd/">
        https://www.sbt-human.com/rgpd/
      </a>
    </p>
    <h2>ARTICLE 4 : Propriété intellectuelle</h2>
    <p>
      Les marques, logos, signes ainsi que tous les contenus du site (textes,
      images, son…) font l&#39;objet d&#39;une protection par le Code de la
      propriété intellectuelle et plus particulièrement par le droit
      d&#39;auteur.
    </p>
    <p>
      La marque Happyneuron est une marque déposée par SBT, société mère de
      HUMAN(S) MATTER.Toute représentation et/ou reproduction et/ou exploitation
      partielle ou totale de cette marque, de quelque nature que ce soit, est
      totalement prohibée.
    </p>
    <p>
      L&#39;Utilisateur doit solliciter l&#39;autorisation préalable du site
      pour toute reproduction, publication, copie des différents contenus. Il
      s&#39;engage à une utilisation des contenus du site dans un cadre
      strictement privé, toute utilisation à des fins commerciales et
      publicitaires est strictement interdite.
    </p>
    <p>
      Toute représentation totale ou partielle de ce site par quelque procédé
      que ce soit, sans l’autorisation expresse de l’exploitant du site Internet
      constituerait une contrefaçon sanctionnée par l’article L 335-2 et
      suivants du Code de la propriété intellectuelle.
    </p>
    <p>
      Il est rappelé conformément à l’article L122-5 du Code de propriété
      intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu
      protégé doit citer l’auteur et sa source.
    </p>
    <h2>ARTICLE 5 : Responsabilité</h2>
    <p>
      Les sources des informations diffusées sur le site flex.happyneuron.fr
      sont réputées fiables mais le site ne garantit pas qu’il soit exempt de
      défauts, d’erreurs ou d’omissions.
    </p>
    <p>
      Les informations communiquées sont présentées à titre indicatif et général
      sans valeur contractuelle. Malgré des mises à jour régulières, le site
      flex.happyneuron.fr ne peut être tenu responsable de la modification des
      dispositions administratives et juridiques survenant après la publication.
      De même, le site ne peut être tenue responsable de l’utilisation et de
      l’interprétation de l’information contenue dans ce site.
    </p>
    <p>
      L&#39;Utilisateur s&#39;assure de garder son mot de passe secret. Toute
      divulgation du mot de passe, quelle que soit sa forme, est interdite. Il
      assume les risques liés à l&#39;utilisation de son identifiant et mot de
      passe. Le site décline toute responsabilité.
    </p>
    <p>
      Le site flex.happyneuron.fr ne peut être tenu pour responsable d’éventuels
      virus qui pourraient infecter l’ordinateur ou tout matériel informatique
      de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement
      provenant de ce site.
    </p>
    <p>
      La responsabilité du site ne peut être engagée en cas de force majeure ou
      du fait imprévisible et insurmontable d&#39;un tiers.
    </p>
    <h2>ARTICLE 6 : Liens hypertextes</h2>
    <p>
      Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est
      informé qu’en cliquant sur ces liens, il sortira du site
      flex.happyneuron.fr. Ce dernier n’a pas de contrôle sur les pages web sur
      lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
      responsable de leur contenu.
    </p>
    <h2>ARTICLE 7 : Cookies</h2>
    <p>
      Les cookies sont de petits fichiers stockés temporairement sur le disque
      dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont
      nécessaires à l’utilisation du site flex.happyneuron.fr. Les cookies ne
      contiennent pas d’information personnelle et ne peuvent pas être utilisés
      pour identifier quelqu’un. Un cookie contient un identifiant unique,
      généré aléatoirement et donc anonyme. Certains cookies expirent à la fin
      de la visite de l’Utilisateur, d’autres restent.
    </p>
    <p>
      L’information contenue dans les cookies est utilisée pour le bon
      fonctionnement du service flex.happyneuron.fr :
    </p>
    <ul>
      <li>
        <p>
          Un cookie fonctionnel temporaire pour conserver la session active le
          long du jeu, et supprimé à la fin du jeu
        </p>
      </li>
      <li>
        <p>
          Un cookie fonctionnel persistant pour les paramétrages que
          l&#39;utilisateur aurait fait dans les jeux (activation de la loupe,
          volume de son).
        </p>
      </li>
    </ul>
    <p>
      L’Utilisateur est informé que lors de ses visites sur le site, un cookie
      peut s’installer automatiquement sur son logiciel de navigation. En
      naviguant sur le site, L’Utilisateur les accepte.
    </p>
    <h2>ARTICLE 8 : Droit applicable et juridiction compétente</h2>
    <p>
      La législation française s&#39;applique au présent contrat. En cas
      d&#39;absence de résolution amiable d&#39;un litige né entre les parties,
      les tribunaux français seront seuls compétents pour en connaître.
    </p>
    <p>
      Pour toute question relative à l’application des présentes CGU, vous
      pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
    </p>
    <p>
      CGU réalisées sur{' '}
      <a href="http://legalplace.fr/">http://legalplace.fr/</a>
    </p>
  </Layout>
);

export default Cgu;
