import React from 'react';
import clx from 'classnames';
import style from './Button.module.scss';

export interface ButtonProps {
  value?: string;
  size?: 'small' | 'medium';
  reversed?: boolean;
  onClick: () => void;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  value = '',
  size = 'medium',
  reversed = false,
  onClick,
  isLoading = false,
}) => (
  <div
    onClick={!isLoading ? onClick : () => null}
    className={clx(
      style.button,
      { [style['button--reversed']]: reversed },
      { [style['button--small']]: size === 'small' },
    )}
    role="button"
  >
    {isLoading ? (
      <div className={style.loader}>
        <div />
        <div />
        <div />
        <div />
      </div>
    ) : (
      children || value
    )}
  </div>
);

export default Button;
