import React from 'react';

import { IRadioImageProps } from './RadioImage.types';

import styles from './RadioImage.module.scss';

const RadioImage: React.FC<IRadioImageProps> = ({
  id,
  name,
  value,
  onChange,
  label,
  image,
  selected,
}) => (
  <div className={`${styles.container} ${selected ? styles.selected : ''}`}>
    <label
      htmlFor={id}
      className={styles.label}
      style={{ backgroundImage: `url(${image})` }}
    >
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </label>
    {label && <p>{label}</p>}
  </div>
);

export default RadioImage;
