import { useEffect } from 'react';

const useLocationHash = (): void => {
  useEffect(() => {
    if (!window.location.hash) return;
    try {
      const target = document.querySelector(window.location.hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (err) {
      console.error(err);
    }
  }, []);
};

export default useLocationHash;
