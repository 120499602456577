import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LogoFlex from '../../assets/images/logo-flex.png';
import LogoHn from '../../assets/images/logo-hn.png';

import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.container}>
      <div className={styles['links-logos']}>
        <div className={styles['']}>
          <div>
            <img src={LogoFlex} alt="logo Flex" width={89} height={101} />
            <img src={LogoHn} alt="logo Happyneuron" width={140} height={128} />
          </div>
          <ul>
            <li>
              <Link to="/cgu" target="_top">
                {t('footer.mentions')}
              </Link>
            </li>
            <li>
              <Link to="/contact" target="_top">
                {t('footer.contact')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.copyright}>{t('footer.copyright')}</div>
    </footer>
  );
};

export default Footer;
