import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header';
import BlockTitle from '../../components/block-title';
import Footer from '../../components/footer';
import EntryForm from '../../components/entry-form';

import { IFormValues } from '../../components/entry-form/EntryForm.types';

import styles from './Profile.module.scss';
import useAxios from '../../utils/useAxios';
import { AppContext } from '../../contexts/AppContext';

const Profile: React.FC = () => {
  const { t } = useTranslation();

  const [userProfile, setUserProfile] = useState<IFormValues>({
    sexe: null,
    birthdate: null,
    education: null,
  });
  const { token, nextRoute, setIsProfileCompleted } = useContext(AppContext);
  const { setProfile, getProfile } = useAxios();
  const history = useHistory();

  useEffect(() => {
    const isUserProfileEmpty = () =>
      Object.values(userProfile).some((value) => value === null);

    if (!token || !isUserProfileEmpty()) return;
    (async () => {
      const currentProfile = await getProfile(token);

      if (currentProfile) {
        const sexe = currentProfile?.gender === 1 ? 'H' : 'F';
        const education = currentProfile?.education as number;
        const birthdate = currentProfile.birthday
          ? new Date(currentProfile.birthday.toString()).getFullYear()
          : null;

        setUserProfile({
          sexe,
          education,
          birthdate,
        });
      }
    })();
  }, [token, getProfile, userProfile]);

  const handleEntryForm = async (formValues: IFormValues) => {
    if (!token) return;
    await setProfile(token, formValues);
    setIsProfileCompleted(true);
    history.push(nextRoute || '/');
  };

  return (
    <>
      <Header />
      <main className={styles.container}>
        <div className={styles.content}>
          <BlockTitle
            title={t('profile.title')}
            subtitle={t('profile.subtitle')}
          />
          <EntryForm
            onSubmitValues={handleEntryForm}
            defaultValues={userProfile}
          />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Profile;
