import React from 'react';

import HeadlineImg from '../../assets/images/flex_illustration.png';
import styles from './Headline.module.scss';

const Headline: React.FC = ({ children }) => (
  <div className={styles.headline}>
    <img
      className={styles.headline__img}
      src={HeadlineImg}
      alt="Illustration choix des jeux"
    />
    <div className={styles.headline__content}>{children}</div>
  </div>
);

export default Headline;
