import React from 'react';
import { Link } from 'react-router-dom';

import LogoFlex from '../../assets/images/logo-flex.png';
import LogoHn from '../../assets/images/logo-hn.png';
import IconHome from '../../assets/images/home.svg';

import { IHeaderProps } from './Header.types';

import styles from './Header.module.scss';

const Header: React.FC<IHeaderProps> = ({ isOnLeft }) => (
  <div className={`${styles.header} ${isOnLeft ? styles.left : styles.top}`}>
    <Link to="/">
      <img className={styles['icon-home']} src={IconHome} alt="Icon Accueil" />
    </Link>
    <Link to="/">
      <img src={LogoFlex} alt="logo flex" width={89} height={101} />
      <img src={LogoHn} alt="logo happyneuron" width={140} height={128} />
    </Link>
  </div>
);

Header.defaultProps = {
  isOnLeft: false,
};

export default Header;
