import img126 from './assets/images/games/126.jpg';
import img98 from './assets/images/games/098.jpg';
import img13 from './assets/images/games/013.jpg';
import img159 from './assets/images/games/159.jpg';
import img87 from './assets/images/games/087.jpg';

export type GameInfoType = {
  gameId: number;
  slug: string;
  title: string;
  category: string;
  description: string;
  information: string;
  imageUrl: string;
};

const list: GameInfoType[] = [
  {
    gameId: 126,
    slug: 'restaurant',
    title: 'Garçon, s’il vous plaît !',
    category: 'Mémoire',
    description:
      "Mettez-vous à la place d'un serveur de restaurant, en essayant de mémoriser et de retrouver le menu choisi par plusieurs convives.",
    information:
      "Cet exercice vous propose d'entraîner votre mémoire récente verbale. Vous devrez vous mettre dans la peau d'un serveur de restaurant, en essayant de mémoriser et rappeler le menu choisi par 2, 3 ou 4 convives. Pour davantage de difficulté, vous pourrez choisir que les convives changent de place entre la commande et le rappel des menus.",
    imageUrl: img126,
  },
  {
    gameId: 98,
    slug: 'pay-attention',
    title: 'Concentrez-vous !',
    category: 'Attention',
    description:
      'Mémorisez des séries de chiffres ou de lettres, pour ensuite les rappeler dans l’ordre alphabétique, numérique…',
    information:
      "Dans cet exercice, vous devrez lire et mémoriser des séries de plus en plus longues de chiffres ou de lettres, que vous devrez ensuite rappeler dans l'ordre, dans l'ordre inverse, dans l’ordre alphabétique ou dans l'ordre numérique selon la variante choisie.",
    imageUrl: img98,
  },
  {
    gameId: 13,
    slug: 'sleight-of-hands',
    title: 'Tours de mains',
    category: 'Visuo-spatial',
    description:
      "Déterminez le plus vite possible si la main qui apparaît à l'écran est une main gauche ou une main droite.",
    information:
      "Cet exercice consiste à déterminer le plus vite possible si la main qui apparaît à l'écran est une main gauche ou une main droite. La main pourra être présentée sous 3 conditions : seule, en action ou vue dans un miroir.",
    imageUrl: img13,
  },
  {
    gameId: 159,
    slug: 'countdown',
    title: 'Au bout du compte',
    category: 'Logique',
    description:
      'Retrouvez un résultat mathématique en choisissant des nombres et des opérateurs et en les combinant entre eux.',
    information:
      'Cet exercice vous propose de retrouver un résultat mathématique en choisissant des nombres et des opérateurs et en les combinant entre eux. Il s’agit d’effectuer des opérations intermédiaires pour arriver au résultat demandé. Vous avez le choix entre une liste plus ou moins longue de nombres et les quatre opérateurs mathématiques classiques.',
    imageUrl: img159,
  },
  {
    gameId: 87,
    slug: 'split-words',
    title: 'Mots coupés',
    category: 'Langage',
    description:
      'Reconstituez le plus vite possible des mots dont les syllabes ont été mélangées dans une grille.',
    information:
      "Le principe de cet exercice est de reconstituer le plus vite possible des mots dont les syllabes ont été mélangées dans une grille. Les mots sont issus d'un même thème et peuvent être composés de deux ou trois syllabes selon la variante choisie.",
    imageUrl: img87,
  },
];

export default list;
