import React from 'react';

import styles from './Message.module.scss';

interface IMessageProps {
  text: string;
}

const Message: React.FC<IMessageProps> = ({ text }) => (
  <div className={styles.container}>
    <p>{text}</p>
  </div>
);

export default Message;
